define("ui/models/multiclusterapp", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "ui/utils/parse-externalid", "ui/utils/constants"], function (_exports, _resource, _denormalize, _parseExternalid, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _C$CATALOG = _constants.default.CATALOG,
      helmV2 = _C$CATALOG.HELM_VERSION_2,
      helmV3 = _C$CATALOG.HELM_VERSION_3;

  var MultiClusterApp = _resource.default.extend({
    catalog: Ember.inject.service(),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    canEdit: false,
    templateVersion: (0, _denormalize.reference)('templateVersionId', 'templateversion', 'globalStore'),
    catalogTemplate: (0, _denormalize.reference)('templateId', 'template', 'globalStore'),
    isHelm3: Ember.computed('helmVersion', function () {
      var _this$helmVersion = this.helmVersion,
          helmVersion = _this$helmVersion === void 0 ? helmV2 : _this$helmVersion;

      if (helmVersion === helmV3) {
        return true;
      }

      return false;
    }),
    externalIdInfo: Ember.computed('templateVersion.externalId', function () {
      return (0, _parseExternalid.parseHelmExternalId)(Ember.get(this, 'templateVersion.externalId'));
    }),
    templateId: Ember.computed('externalIdInfo.{templateId}', function () {
      return Ember.get(this, 'externalIdInfo.templateId');
    }),
    canUpgrade: Ember.computed('actionLinks.{upgrade}', 'catalogTemplate', 'templateVersion', function () {
      var l = Ember.get(this, 'links') || {};
      return !!l.update && !Ember.isEmpty(this.catalogTemplate);
    }),
    canClone: Ember.computed('catalogTemplate', 'templateVersion', function () {
      return !Ember.isEmpty(this.catalogTemplate);
    }),
    canRollback: Ember.computed('catalogTemplate', 'templateVersion', function () {
      return !Ember.isEmpty(this.catalogTemplate) && !!(this.actionLinks || {}).rollback;
    }),
    availableActions: Ember.computed('actionLinks.{rollback}', 'links.{update}', 'canUpgrade', 'canRollback', function () {
      return [{
        label: 'action.upgrade',
        icon: 'icon icon-edit',
        action: 'upgrade',
        enabled: Ember.get(this, 'canUpgrade')
      }, {
        label: 'action.rollback',
        icon: 'icon icon-history',
        action: 'rollback',
        enabled: Ember.get(this, 'canRollback')
      }];
    }),
    actions: {
      upgrade: function upgrade() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');
        var vKeys = Object.keys(Ember.get(this, 'catalogTemplate.versionLinks'));
        var latestVersion = vKeys[vKeys.length - 1];
        Ember.get(this, 'router').transitionTo('global-admin.multi-cluster-apps.catalog.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            upgrade: latestVersion
          }
        });
      },
      rollback: function rollback() {
        Ember.get(this, 'modalService').toggleModal('modal-rollback-mc-app', {
          originalModel: this,
          revisionsLink: this.links.revisions
        });
      },
      clone: function clone() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');
        Ember.get(this, 'router').transitionTo('global-admin.multi-cluster-apps.catalog.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            clone: true
          }
        });
      }
    }
  });

  var _default = MultiClusterApp;
  _exports.default = _default;
});