define("ui/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45KJcgnu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"underlay\"],[10,\"class\",\"underlay hide\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"loading-underlay\"],[10,\"class\",\"underlay\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"loading-overlay\"],[10,\"class\",\"loading-overlay\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"settings\",\"isRancher\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"square\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"lid\"],[8],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"whiteboxloader\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"loader-inner ball-clip-rotate-pulse\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[1,[28,\"outlet\",[\"overlay\"],null],false],[0,\"\\n\"],[1,[28,\"outlet\",[\"error\"],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"application\"],[10,\"class\",\"container no-inline-space\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"component\",[[24,[\"tooltip\"]]],[[\"tooltipTemplate\",\"class\",\"id\",\"role\",\"aria-hidden\"],[[24,[\"tooltipTemplate\"]],\"container-tooltip\",\"tooltip-base\",\"tooltip\",\"false\"]]],false],[0,\"\\n\"],[1,[22,\"modal-root\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/application/template.hbs"
    }
  });

  _exports.default = _default;
});